<template>
  <div class="whiteList">
    <!-- <div class="main-Title bgff"><h2>White List</h2></div> -->
    <div class="mainBox">
      <el-card shadow="hover" class="mb10">
        <el-form v-model="filterForm" size="small" label-width="60px">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label="OfferId" class="mb0">
                <el-input
                  v-model.number="filterForm.offerId"
                  placeholder="Please enter offer id"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :xs="24" :sm="24" :md="7" :lg="7" :xl="7">
              <el-form-item label-width="0" class="mb0">
                <el-button type="primary" :loading="loading.list" @click="getList(1)"
                  >Search</el-button
                >
                <el-button type="primary" @click="openDialog('add')">Create</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-card>
      <el-card shadow="hover">
        <el-table
          v-loading="loading.list"
          ref="tableRef"
          style="width:100%;min-height: 70vmin;"
          stripe
          border
          :data="list"
          highlight-current-row
          aria-setsize="mini"
          size="mini"
          class="mb10"
        >
          <el-table-column label="id" prop="id" align="center"></el-table-column>
          <el-table-column label="offerId" prop="offerId" align="center"></el-table-column>
          <el-table-column label="createTime" prop="createTime" align="center"></el-table-column>
          <el-table-column label="operation" align="center">
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="deletes(scope.row.id)"
                >Delete</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-dialog :title="dialog.title" :visible.sync="dialog.visible" width="30%">
          <el-form
            v-model="postData"
            size="small"
            :model="postData"
            ref="postDataForm"
            label-width="80px"
            :rules="rules"
          >
            <el-form-item label="OfferIds" prop="offerIds">
              <el-input
                v-model.trim="postData.offerIds"
                placeholder="输入offerId用,隔开，例子：8145,8143 "
              ></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="closeDialog('postDataForm')" size="mini">取 消</el-button>
            <el-button
              type="primary"
              @click="submitDialog('postDataForm')"
              :loading="loading.submitBtn"
              >确 定</el-button
            >
          </span>
        </el-dialog>
        <pagination
          :pageTotal="total"
          @handleSizeChange="handleSizeChange"
          @handleCurrentChange="handleCurrentChange"
          :pageSize="filterForm.pageSize"
          :currentPage="filterForm.page"
        ></pagination>
      </el-card>
    </div>
  </div>
</template>

<script>
  import whitelistCon from '../controllers/setting/whitelist';
  export default {
    name: 'whitelist',
    ...whitelistCon,
  };
</script>

<style></style>
